import React from "react"
import {graphql} from "gatsby"
import shuffle from "shuffle-array"
import MainLayout from "Layout/layout.jsx"
import Footer from "Layout//Footer/Footer.jsx"
import FlexibleContent from "Layout/FlexibleContent/FlexibleContent.jsx"
import PageTransition from "Layout/PageTransition/PageTransition.jsx"
import {dynamicContentShuffle} from "../helper/dynamicContentShuffle"
import {metaData} from "../helper/metaData"

const Job = ({data, location, pageContext}) => {
  const {page} = data
  let {relatedProjects, relatedStories} = data

  const dynamicContent = dynamicContentShuffle(relatedProjects, relatedStories)

  relatedProjects = shuffle(relatedProjects.edges)
  relatedStories = shuffle(relatedStories.nodes).slice(0, 3)

  return (
    <MainLayout
      location={location}
      metaData={metaData(page)}
      pageContext={pageContext}
      translations={page.translations}
    >
      <PageTransition>
        {
          page.flex.content.length > 0 && (
            <FlexibleContent
              content={page.flex.content}
              dynamicContent={dynamicContent}
              location={location}
              pageName="index"
              relatedProjects={relatedProjects}
              relatedStories={relatedStories}
              slug={pageContext.slug}
            />
          )
        }
      </PageTransition>
      {page.footer && (<Footer teasers={page.footer.footerTeasers} headline={page.footer.headline} location={location} lang={pageContext.lang} translations={page.translations} />)}
    </MainLayout>
  )
}

export const jobQuery = graphql`query jobPageQuery($id: String!, $lang: String!) {
  page: wpContentNode(id: {eq: $id}) {
    ... on WpJob {
      title
      databaseId
      flex {
        content {
          __typename
          ...JobHeroSectionFragment
          ...JobHeroSectionSliderFragment
          ...JobServicesTeaserSectionFragment
          ...JobBasicSliderSectionFragment
          ...JobTeaserSectionFragment
          ...JobProgressSliderSectionFragement
          ...JobRelatedProjectsSectionFragment
          ...JobRelatedStorySectionFragment
          ...JobTextItemsColumnsSectionFragment
          ...JobJobOverviewSectionFragment
          ...JobJobTeaserSectionFragment
          ...JobContactPersonsSectionFragment
          ...JobLocationSectionFragment
          ...JobInquirySectionFragment
          ...JobHeadlineSectionFragment
          ...JobTextSectionFragment
          ...JobTextAndImagesColumnSectionFragment
          ...JobProjectOverviewSectionFragment
          ...JobCompetenceSectionFragment
          ...JobTeamOverviewSectionFragment
          ...JobTimelineSliderSectionFragement
          ...JobNewsOverviewSectionFragment
          ...JobCurrentNewsTeaserSectionFragment
          ...JobFeaturedContentSectionFragment
          ...JobDynamicContentSectionFragment
          ...JobJobApplicationSectionFragment
          ...JobEmbedSectionFragment
        }
      }
      translations {
        uri
        language {
          slug
        }
      }
      ...JobFooterFragment
    }
  }
  relatedProjects: allWpProject(filter: {id: {ne: $id}, language: {slug: {eq: $lang}}, title: {ne: "DO NOT DELETE"}}) {
    edges {
      node {
        title
        slug
        uri
        date
        databaseId
        nodeType
        postImages {
          postImages {
            portaitImage {
              sourceUrl
              mediaDetails {
                height
                width
              }
              altText
              title
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 862, quality: 80, placeholder: NONE, layout: CONSTRAINED)
                }
              }
            }
            landscapeImage {
              sourceUrl
              mediaDetails {
                height
                width
              }
              altText
              title
              localFile {
                childImageSharp {
                  gatsbyImageData(quality: 80, placeholder: NONE, layout: FULL_WIDTH)
                }
              }
            }
          }
        }
      }
    }
  }
  relatedStories: allWpPost(filter: {id: {ne: $id}, language: {slug: {eq: $lang}}, title: {ne: "DO NOT DELETE"}}) {
    nodes {
      title
      slug
      uri
      date
      databaseId
      nodeType
      featuredImage {
        node {
          sourceUrl
          mediaDetails {
            height
            width
          }
          altText
          title
          localFile {
            childImageSharp {
              gatsbyImageData(quality: 80, placeholder: NONE, layout: FULL_WIDTH)
            }
          }
        }
      }
    }
  }
}
`

export default Job
